import useSWR from "swr";
import { noTokenFetcher } from "./swrFetcher";
import createQuery from "./createQuery";

const closedFilter = createQuery({
  filters: { status: "Closed" },
  sort: "startDate:desc",
  limit: 4,
  populate: "deep",
});

const individualFilter = createQuery({
  filters: { status: "Individual" },
  sort: "startDate:desc",
  limit: 10,
  populate: "deep",
});

const combinedFilter = createQuery({
  filters: { status: "Combined" },
  sort: "startDate:desc",
  limit: 10,
  populate: "deep",
});

const visibilityFilter = createQuery({
  filters: { status: "Visibility" },
  sort: "startDate:desc",
  limit: 10,
  populate: "deep",
});

const openAuctionFilters = [individualFilter, combinedFilter, visibilityFilter];

function getHomeAuctions() {
  let insolvencies = {
    recentlyClosedInsolvencies: [],
    ongoingInsolvencies: [],
    upcomingInsolvencies: [],
  };

  const { data: closedInsolvencies } = useSWR(
    `/auctions/getPublicAuctions?${closedFilter}`,
    noTokenFetcher
  );

  if (closedInsolvencies && closedInsolvencies.auctions.length > 0) {
    insolvencies.recentlyClosedInsolvencies.push(
      ...closedInsolvencies.auctions
    );
  }

  const generalDataResults = openAuctionFilters.map((filter) =>
    useSWR(`/auctions/getPublicAuctions?${filter}`, noTokenFetcher)
  );

  const today = new Date();
  const upcomingInsolvencies = [];
  const ongoingInsolvencies = [];

  generalDataResults.forEach(({ data }) => {
    if (data && data.auctions) {
      data.auctions.forEach((auction) => {
        if (
          new Date(auction.startDate) > today &&
          upcomingInsolvencies.length < 4
        ) {
          upcomingInsolvencies.push(auction);
        }
        if (
          new Date(auction.startDate) <= today &&
          new Date(auction.endDate) >= today &&
          ongoingInsolvencies.length < 4
        ) {
          ongoingInsolvencies.push(auction);
        }
      });
    }
  });

  insolvencies.upcomingInsolvencies = upcomingInsolvencies.sort(
    (a, b) => new Date(b.startDate) - new Date(a.startDate)
  );
  insolvencies.ongoingInsolvencies = ongoingInsolvencies.sort(
    (a, b) => new Date(b.startDate) - new Date(a.startDate)
  );

  return insolvencies;
}

export default getHomeAuctions;
