import { PageHeader, Button, Skeleton, Result } from "antd";
import useTranslation from "next-translate/useTranslation";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import Link from "next/link";
import dynamic from "next/dynamic";
import useSWR from "swr";
import { useSelector, useDispatch } from "react-redux";
import { viewerStatus } from "../store/userInfo";

import Navbar from "../components/layout/NavBar";
import Container from "../components/layout/Container";
import ContentContainer from "../components/layout/ContentContainer";
import FooterContainer from "../components/layout/FooterContainer";
import HeroContainer from "../components/layout/HeroContainer";
import AuctioningProcess from "../components/data_display/AuctioningProcess";
import HeadTag from "../components/seo/HeadTag";

import { noTokenFetcher } from "../utils/swrFetcher";
import emptyInsolvency from "../utils/emptyInsolvency";
import createQuery from "../utils/createQuery";
import getHomeAuctions from "../utils/getHomeAuctions";

const InsolvencyGrid = dynamic(
  () => import("../components/data_display/InsolvencyGrid"),
  { ssr: false }
);

// the homepage
function Home({ user }) {
  const { t } = useTranslation("home");
  const router = useRouter();
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.userInfo);

  const data = getHomeAuctions();

  const {
    ongoingInsolvencies,
    recentlyClosedInsolvencies,
    upcomingInsolvencies,
  } = data;

  const ongoingFilter = createQuery({
    filters: { status: "Ongoing" },
    sort: "startDate:desc",
    limit: 4,
    populate: "deep",
  });

  const closedFilter = createQuery({
    filters: { status: "Closed" },
    sort: "startDate:desc",
    limit: 4,
    populate: "deep",
  });

  const upcomingFilter = createQuery({
    filters: { status: "Upcoming" },
    sort: "startDate:desc",
    limit: 4,
    populate: "deep",
  });

  const noOngoingInsolvency =
    !ongoingInsolvencies || ongoingInsolvencies?.length < 1;

  const renderUpcomingInsolvencies = () => {
    if (upcomingInsolvencies == null) {
      return (
        <InsolvencyGrid
          noPagination
          user={user}
          insolvencies={[emptyInsolvency(0), emptyInsolvency(1)]}
        />
      );
    }

    return <InsolvencyGrid noPagination insolvencies={upcomingInsolvencies} />;
  };

  const renderOngoingInsolvencies = () => {
    if (ongoingInsolvencies == null) {
      return (
        <InsolvencyGrid
          noPagination
          insolvencies={[emptyInsolvency(0), emptyInsolvency(1)]}
          user={user}
        />
      );
    }

    if (noOngoingInsolvency) {
      return (
        <InsolvencyGrid
          noPagination
          user={user}
          insolvencies={recentlyClosedInsolvencies}
        />
      );
    }

    return (
      <InsolvencyGrid
        noPagination
        insolvencies={ongoingInsolvencies}
        user={user}
      />
    );
  };

  const makeSeeInsolvenciesButton = (type) => {
    const filter = type === "upcoming" ? "Upcoming" : "Ongoing";
    const nextPath = `/insolvencies?status=${filter}`;

    if (reduxUser?.currentUser) {
      return (
        <Button key={`${type}-auctions`}>
          <Link href={nextPath}>{t(`all_${type}_insolvencies`)}</Link>
        </Button>
      );
    }

    return (
      <Button
        key={`${type}-auctions`}
        onClick={() => dispatch(viewerStatus(true, nextPath))}
      >
        {t(`all_${type}_insolvencies`)}
      </Button>
    );
  };

  return (
    <Container>
      <HeadTag
        title={t("head_title")}
        description={t("head_description")}
        url={router.pathname}
      />
      <Navbar user maxWidth />
      <Container>
        <HeroContainer
          title={t("hero_title")}
          description={t("hero_description")}
          buttons
        />
        <ContentContainer maxWidth>
          <AuctioningProcess duration={12000} />
          <PageHeader
            className="title-bar"
            title={
              !noOngoingInsolvency
                ? t("ongoing_insolvencies")
                : t("recently_closed_title")
            }
            subTitle={noOngoingInsolvency && t("recently_closed_subtitle")}
            extra={
              ongoingInsolvencies?.length > 0
                ? [makeSeeInsolvenciesButton("ongoing")]
                : null
            }
          />
          {renderOngoingInsolvencies()}

          <PageHeader
            className="title-bar"
            title={t("upcomming_insolvencies")}
            extra={
              upcomingInsolvencies?.length > 0 && [
                makeSeeInsolvenciesButton("upcoming"),
              ]
            }
            style={{ marginTop: 60 }}
          />
          {renderUpcomingInsolvencies()}
        </ContentContainer>
      </Container>
      <FooterContainer maxWidth />
    </Container>
  );
}

export const getServerSideProps = async ({ req, query }) => {
  let currentUser;
  if (req?.cookies?.["persist%3AuserInfo"]) {
    const user = JSON.parse(req.cookies["persist%3AuserInfo"]);
    if (user?.currentUser) {
      currentUser = JSON.parse(user.currentUser);
    }
  } else {
    currentUser = null;
  }

  // Temp fix to prevent infinite loop.
  if (query.login && currentUser) {
    return {
      redirect: {
        destination: "/",
        permanent: false,
      },
    };
  }

  return {
    props: {
      user: currentUser,
    },
  };
};

Home.defaultProps = {
  user: null,
};

Home.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    currentUser: PropTypes.object,
  }),
};

export default Home;
