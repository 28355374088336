const emptyInsolvency = (id) => ({
  id,
  status: null,
  endDate: null,
  startDate: null,
  insolvency: {
    images: null,
    name: null,
    description: null,
    currentBid: null,
    id: null,
    publishedAt: null,
  },
  user: null,
  favourites: null,
  author: null,
  auctionType: null,
});

export default emptyInsolvency;
